import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { useSpring, animated } from "react-spring"

import SEO from "../components/seo"

import metaImage from "../images/bri-stauss.jpg"
import SocialMedia from "../components/home/socialMedia"

export const query = graphql`
  query HomePagequery {
    heroImage: file(relativePath: { eq: "bri-stauss.jpg" }) {
      childImageSharp {
        fluid(quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    chriAndBriImage: file(relativePath: { eq: "chris-and-bri-album.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ltyhImage: file(
      relativePath: { eq: "the-bachelor-presents-listen-to-your-heart.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default ({
  data: {
    heroImage: {
      childImageSharp: { fluid: heroImageFluid },
    },
    chriAndBriImage: {
      childImageSharp: { fluid: chriAndBriImageFluid },
    },
    ltyhImage: {
      childImageSharp: { fluid: ltyhImageFluid },
    },
  },
}) => {
  const springProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })

  return (
    <>
      <SEO
        title="Home"
        description="Bri Stauss's Website"
        type="article"
        image={metaImage}
        pathname="home"
      />
      <animated.section
        className="p-4 pt-0"
        style={{ marginTop: "86px", ...springProps }}
      >
        <div className="flex flex-wrap -mx-2">
          <div className="w-full lg:w-1/2 mb-4 px-2">
            <Img
              className="img h-full"
              fluid={heroImageFluid}
              loading="eager"
            />
          </div>
          <div className="w-full lg:w-1/2 mb-4 px-2 flex flex-col">
            <div className="text-center mb-4">
              <span className="text-2xl">Find me on:</span>
            </div>
            <SocialMedia />
            <div className="px-2">
              <div className="flex flex-wrap -mx-2">
                <div className="w-full sm:w-1/2 px-2 flex flex-col">
                  <div className="flex-1 text-center mt-6 mb-4">
                    <span className="text-2xl">
                      Debut album{" "}
                      <a
                        className="hover:opacity-75"
                        href="https://chrisandbrimusic.com/music/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>Chris and Bri</strong>
                      </a>{" "}
                      available for purchase on all streaming platforms
                    </span>
                  </div>
                  <a
                    href="https://chrisandbrimusic.com/music/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div style={{ paddingTop: "100%" }} className="relative">
                      <Img
                        className="absolute inset-0"
                        style={{
                          height: "100%",
                          position: "absolute",
                          width: "100%",
                        }}
                        fluid={chriAndBriImageFluid}
                        loading="eager"
                      />
                    </div>
                  </a>
                </div>
                <div className="w-full sm:w-1/2 px-2 flex flex-col">
                  <div className="flex-1 text-center mt-6 mb-4">
                    <span className="text-2xl">
                      Season 1 winner of{" "}
                      <a
                        className="hover:opacity-75"
                        href="https://abc.com/shows/the-bachelor-presents-listen-to-your-heart"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>
                          The Bachelor Presents: Listen to Your Heart
                        </strong>
                      </a>
                    </span>
                  </div>
                  <a
                    href="https://abc.com/shows/the-bachelor-presents-listen-to-your-heart"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      style={{ paddingTop: "100%" }}
                      className="relative h-full"
                    >
                      <Img
                        className="absolute inset-0"
                        style={{
                          height: "100%",
                          position: "absolute",
                          width: "100%",
                        }}
                        fluid={ltyhImageFluid}
                        loading="eager"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </animated.section>
    </>
  )
}
