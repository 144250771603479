import React from "react"

const SocialMedia = () => {
  return (
    <div className="px-2">
      <div className="flex flex-wrap -mx-2">
        <div className="w-full sm:w-1/2 px-2">
          <a
            href="https://www.instagram.com/bristauss/"
            className="group flex justify-center items-center border-4 border-black hover:border-instagram hover:text-instagram mb-4 p-4 transition duration-500 ease"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg
              className="w-10 h-10 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913a5.885 5.885 0 001.384 2.126A5.868 5.868 0 004.14 23.37c.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 002.126-1.384 5.86 5.86 0 001.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 00-1.384-2.126A5.847 5.847 0 0019.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 01-.899 1.382 3.744 3.744 0 01-1.38.896c-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 01-1.379-.899 3.644 3.644 0 01-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678a6.162 6.162 0 100 12.324 6.162 6.162 0 100-12.324zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405a1.441 1.441 0 01-2.88 0 1.44 1.44 0 012.88 0z" />
            </svg>
            <span className="ml-4">Instagram</span>
          </a>
        </div>
        <div className="w-full sm:w-1/2 px-2">
          <a
            href="https://open.spotify.com/artist/7jSoZXliIn37RwPWVu6qUw"
            className="group flex justify-center items-center border-4 border-black hover:border-spotify hover:text-spotify mb-4 p-4 transition duration-500 ease"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg
              className="w-10 h-10 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z" />
            </svg>
            <span className="ml-4">Spotify</span>
          </a>
        </div>
        <div className="w-full sm:w-1/2 px-2">
          <a
            href="https://music.apple.com/us/artist/bri-stauss/1002086123"
            className="group flex justify-center items-center border-4 border-black hover:border-gray-800 hover:text-gray-800 mb-4 p-4 transition duration-500 ease"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg
              className="w-10 h-10 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23.997 6.124a9.23 9.23 0 00-.24-2.19c-.317-1.31-1.062-2.31-2.18-3.043A5.022 5.022 0 0019.7.164a10.497 10.497 0 00-1.564-.15c-.04-.003-.083-.01-.124-.013H5.988c-.152.01-.303.017-.455.026C4.786.07 4.043.15 3.34.428 2.004.958 1.04 1.88.475 3.208c-.192.448-.292.925-.363 1.408a10.61 10.61 0 00-.1 1.18c0 .032-.007.062-.01.093v12.223c.01.14.017.283.027.424.05.815.154 1.624.497 2.373.65 1.42 1.738 2.353 3.234 2.802.42.127.856.187 1.293.228.555.053 1.11.06 1.667.06h11.03c.525 0 1.048-.034 1.57-.1.823-.106 1.597-.35 2.296-.81a5.046 5.046 0 001.88-2.208c.186-.42.293-.87.37-1.324.113-.675.138-1.358.137-2.04-.002-3.8 0-7.595-.003-11.393zm-6.423 3.99v5.712c0 .417-.058.827-.244 1.206-.29.59-.76.962-1.388 1.14-.35.1-.706.157-1.07.173-.95.045-1.773-.6-1.943-1.536a1.88 1.88 0 011.038-2.022c.323-.16.67-.25 1.018-.324.378-.082.758-.153 1.134-.24.274-.063.457-.23.51-.516a.904.904 0 00.02-.193c0-1.815 0-3.63-.002-5.443a.725.725 0 00-.026-.185c-.04-.15-.15-.243-.304-.234-.16.01-.318.035-.475.066-.76.15-1.52.303-2.28.456l-2.326.47-1.374.278c-.016.003-.032.01-.048.013-.277.077-.377.203-.39.49-.002.042 0 .086 0 .13-.002 2.602 0 5.204-.003 7.805 0 .42-.047.836-.215 1.227-.278.64-.77 1.04-1.434 1.233-.35.1-.71.16-1.075.172-.96.036-1.755-.6-1.92-1.544-.14-.812.23-1.685 1.154-2.075.357-.15.73-.232 1.108-.31.287-.06.575-.116.86-.177.383-.083.583-.323.6-.714v-.15c0-2.96 0-5.922.002-8.882 0-.123.013-.25.042-.37.07-.285.273-.448.546-.518.255-.066.515-.112.774-.165.733-.15 1.466-.296 2.2-.444l2.27-.46c.67-.134 1.34-.27 2.01-.403.22-.043.443-.088.664-.106.31-.025.523.17.554.482.008.073.012.148.012.223.002 1.91.002 3.822 0 5.732z" />
            </svg>
            <span className="ml-4">Apple Music</span>
          </a>
        </div>
        <div className="w-full sm:w-1/2 px-2">
          <a
            href="https://geo.music.apple.com/us/artist/bri-stauss/1002086123?mt=1&app=music"
            className="group flex justify-center items-center border-4 border-black hover:border-itunes hover:text-itunes mb-4 p-4 transition duration-500 ease"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg
              className="w-10 h-10 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.979 24c-2.483 0-4.898-.777-6.954-2.262a11.929 11.929 0 01-4.814-7.807 11.956 11.956 0 012.091-8.937 11.85 11.85 0 017.78-4.835 11.83 11.83 0 018.896 2.104 11.934 11.934 0 014.815 7.807 11.96 11.96 0 01-2.091 8.938 11.855 11.855 0 01-7.78 4.835c-.649.105-1.299.157-1.943.157zm-6.474-2.926a11.022 11.022 0 008.284 1.96 11.044 11.044 0 007.246-4.504c3.583-5.004 2.445-12.004-2.538-15.604a11.022 11.022 0 00-8.284-1.96A11.046 11.046 0 002.968 5.47C-.616 10.474.523 17.474 5.505 21.074zm10.606-3.552a2.08 2.08 0 001.458-1.468l.062-.216.008-5.787c.006-4.334 0-5.814-.024-5.895a.535.535 0 00-.118-.214.514.514 0 00-.276-.073c-.073 0-.325.035-.56.078-1.041.19-7.176 1.411-7.281 1.45a.786.786 0 00-.399.354l-.065.128s-.031 9.071-.078 9.173a.7.7 0 01-.376.35 9.428 9.428 0 01-.609.137c-1.231.245-1.688.421-2.075.801-.22.216-.382.51-.453.819-.067.295-.045.737.051 1.006.1.281.262.521.473.71.192.148.419.258.674.324.563.144 1.618-.016 2.158-.328a2.36 2.36 0 00.667-.629c.06-.089.15-.268.2-.399.176-.456.181-8.582.204-8.684a.44.44 0 01.32-.344c.147-.039 6.055-1.207 6.222-1.23.146-.02.284.027.36.12a.29.29 0 01.109.096c.048.071.051.213.058 2.785.008 2.961.012 2.892-.149 3.079-.117.136-.263.189-.864.311-.914.187-1.226.275-1.576.446-.437.213-.679.447-.867.836a1.58 1.58 0 00-.182.754c.001.489.169.871.55 1.245.035.034.069.066.104.097.192.148.387.238.633.294.37.082 1.124.025 1.641-.126z" />
            </svg>
            <span className="ml-4">iTunes</span>
          </a>
        </div>
        <div className="w-full sm:w-1/2 px-2">
          <a
            href="https://twitter.com/bristauss"
            className="group flex justify-center items-center border-4 border-black hover:border-twitter hover:text-twitter mb-4 p-4 transition duration-500 ease"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg
              className="w-10 h-10 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23.954 4.569a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.061a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.937 4.937 0 004.604 3.417 9.868 9.868 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 002.46-2.548l-.047-.02z" />
            </svg>
            <span className="ml-4">Twitter</span>
          </a>
        </div>
        <div className="w-full sm:w-1/2 px-2">
          <a
            href="https://www.facebook.com/bri.stauss"
            className="group flex justify-center items-center border-4 border-black hover:border-facebook hover:text-facebook mb-4 p-4 transition duration-500 ease"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg
              className="w-10 h-10 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23.998 12c0-6.628-5.372-12-11.999-12C5.372 0 0 5.372 0 12c0 5.988 4.388 10.952 10.124 11.852v-8.384H7.078v-3.469h3.046V9.356c0-3.008 1.792-4.669 4.532-4.669 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.49 0-1.955.925-1.955 1.874V12h3.328l-.532 3.469h-2.796v8.384c5.736-.9 10.124-5.864 10.124-11.853z" />
            </svg>
            <span className="ml-4">Facebook</span>
          </a>
        </div>
        <div className="w-full sm:w-1/2 px-2">
          <a
            href="https://www.youtube.com/channel/UCoI8HaTVPB0fZZl3WbkARhA"
            className="group flex justify-center items-center border-4 border-black hover:border-youtube hover:text-youtube mb-4 p-4 transition duration-500 ease"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg
              className="w-10 h-10 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23.495 6.205a3.007 3.007 0 00-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 00.527 6.205a31.247 31.247 0 00-.522 5.805 31.247 31.247 0 00.522 5.783 3.007 3.007 0 002.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 002.088-2.088 31.247 31.247 0 00.5-5.783 31.247 31.247 0 00-.5-5.805zM9.609 15.601V8.408l6.264 3.602z" />
            </svg>
            <span className="ml-4">YouTube</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default SocialMedia
